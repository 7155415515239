import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../states/userState';
import { historyState } from '../states/historyState';
import { assetsAPI } from '../api/assets';
import { useCallback } from 'react';

export const useUpdateHistory = () => {
  const user = useRecoilValue(userState);
  const setHistory = useSetRecoilState(historyState);
  const updateHistory = useCallback(async () => {
    if (user) {
      const { data } = await assetsAPI.getHistory(user.ethAddress);
      setHistory(data);
    }
  }, [user, setHistory]);
  return updateHistory;
};
