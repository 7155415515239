import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { tokens } from '../../constants/tokens';
import { userState } from '../../states/userState';
import { PositionItem } from '../PositionItem/PositionItem';
import { History } from '../History/History';
import { userAPI } from '../../api/user';
import './MainInfo.scss';
import { UserBalance } from '../UserBalance/UserBalance';
import AssetsBar from '../AssetsBar/AssetsBar';
import { assetsAPI } from '../../api/assets';
import { positionsState } from '../../states/positionsState';

interface IPosition {
  id: string;
  amount: number;
  price: number;
  asset: {
    symbol: string;
    color: string;
    name: string;
    tag: string;
  };
  type: string;
  usdt: number;
  stop_loss: string;
  take_profit: string;
}

export const MainInfo = () => {
  const user = useRecoilValue(userState);
  const [isShowingPositions, setIsShowingPositions] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [positions, setPositions] = useRecoilState(positionsState);

  useEffect(() => {
    const someArray: any = [];
    if (user) {
      (async () => {
        const { data } = await assetsAPI.getPositions(user.ethAddress);
        data.map((item: any) => {
          const token: any = item;

          if (Number(token.amount) > 0) {
            someArray.push(token);
          }
        });
        setPositions(someArray);
      })();
    }
  }, [user, setPositions]);
  // useEffect(() => {
  //   if (positions.length > 0) {
  //     const interval = setInterval(() => {
  //       let price = '';
  //       positions.forEach((item) => {
  //         fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${item.asset.symbol.toUpperCase()}USDT`)
  //           .then((response) => response.json())
  //           .then((data) => (price = data.price))
  //           .then(() => {
  //             if (
  //               Number(item.stop_loss) >= Number(price) ||
  //               (item.take_profit !== '' && Number(price) >= Number(item.take_profit))
  //             )
  //               userAPI.closePosition(user.ethAddress, item.asset.symbol, item.id).then((res) => {
  //                 if (res.data['Long possition'] === 'Close!' || res.data['Short possition'] === 'Close!') {
  //                   userAPI
  //                     .getUser(user.ethAddress)
  //                     .then((res) => {
  //                       setUser(res.data);
  //                     })
  //                     .catch((error) => console.log(error));
  //                 }
  //               });
  //           });
  //       });
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }
  // }, [positions, user, setUser]);
  return (
    <div className={cn('main-info', { 'main-info_opened': isShowingPositions })}>
      <div className='main-info__wrap'>
        <div className='main-info__head'>
          <UserBalance />
          {/* {total} */}
          {/* {isConnected && (
            <ArrowSvg
              onClick={() => setIsShowingPositions(!isShowingPositions)}
              className={cn('main-info__arrow', { 'main-info__arrow_active': isShowingPositions })}
            />
          )} */}
          <div className='main-info__tabs'>
            <div
              onClick={() => {
                if (activeTabIndex === 0) return setIsShowingPositions(!isShowingPositions);
                setActiveTabIndex(0);
                setIsShowingPositions(true);
              }}
              className={cn('main-info__tab', {
                'main-info__tab_active': activeTabIndex === 0 && isShowingPositions,
              })}
            >
              Open positions
            </div>
            <div
              onClick={() => {
                if (activeTabIndex === 1) return setIsShowingPositions(!isShowingPositions);
                setActiveTabIndex(1);
                setIsShowingPositions(true);
              }}
              className={cn('main-info__tab', {
                'main-info__tab_active': activeTabIndex === 1 && isShowingPositions,
              })}
            >
              History
            </div>
          </div>
        </div>
        {positions.length > 0 && <AssetsBar assets={positions} />}
      </div>
      {isShowingPositions && (
        <div className={'main-info__drop'}>
          <div
            style={{ height: isShowingPositions ? 'fit-content' : '0' }}
            className={'main-info__positions-wrap'}
          >
            <div
              className={cn('main-info__positions-list', {
                'main-info__positions-list_visible': activeTabIndex === 0,
              })}
            >
              {/* {positionsList} */}
              {positions.length > 0 ? (
                positions.map((position: any) => (
                  <PositionItem
                    key={position.symbol}
                    isButton={true}
                    currentToken={position}
                    // currentPrice={prices[item.symbol]}
                    // position={position}
                    buyValueUsdt={position.usdt.toString()}
                  />
                ))
              ) : (
                <div className='main-info__no-positions'>
                  <h2>You don`t have opened positions</h2>
                </div>
              )}
            </div>

            <History isVisible={activeTabIndex === 1} />
          </div>
        </div>
      )}
    </div>
  );
};
