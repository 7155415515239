import { atom } from 'recoil';

export type IPosition = {
  id: string;
  amount: number;
  price: number;
  asset: {
    symbol: string;
    color: string;
    name: string;
    tag: string;
  };
  type: string;
  usdt: number;
  stop_loss: string;
  take_profit: string;
};

export const positionsState = atom<IPosition[]>({ key: 'positionsState', default: [] });
